#contactUsPreviewComponent{
    background-color: #F7F7F7;
    border: 2px solid #F7F7F7;
    padding: 40px 0 30px 0;


    #intro{
        padding: 0 15px;

        p{
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 27px;
            color: #595959;
        }

        h2{
            font-family: 'Yeseva One', cursive;
            font-size: 58px;
            line-height: 75px;
        }

        h4{
            display: none;
        }
    }

    #form{
        input, textarea{
            display: block;
            margin: 20px auto;
            border: solid 2px #D7D7D7;
            height: 45px;
            width: 70%;
            padding: 12px 12px;
            font-family: 'Roboto', sans-serif;
            font-size: 13.5px;
            line-height: 20px;

            &::placeholder{
                color: #111111;
                overflow-x: none;
            }

            &#message{
                height: 170px;
            }
        }

        p{
            color: green;
            font-weight: bold;
            text-align: center;
        }
        
        button{
            display: block;
            margin: 0 auto;
            height: 40px;

            img{
                display: none;
            }
        }
    }
}


@media(min-width:900px){
    #contactUsPreviewComponent{  
        #intro{
            padding: 0 15%;
    
            h4{
                display: block;
            }
        }
    }
}
