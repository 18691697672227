#latestProgramsPreviewComponent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
    row-gap: 150px;
    background-color: #FFFFFF;

    .latestProgram{
        position: relative;
        margin: 0 auto;

        .cardTop{
            height: 160px;
            width: 173px;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 11px 0 0 11px;

            .date{
                width: 55px;
                height: 50px;
                background-color: #FC6539;
                overflow: hidden;
                text-align: center;
                color: #FFFFFF;
                padding-top: 5px;

                h2{
                    padding: 0;
                    margin: 0;
                }
        
                .day{
                    font-weight: 300;
                    font-family: "Yeseva One", "sans-serrif";
                    font-size: 16px;
                    line-height: 15px;
                }
        
                .month{
                    display: block;
                    font-family: "Roboto", "sans-serrif";
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 300;
                    letter-spacing: 1.2px;
                }
        
                .year{
                    font-weight: 300;
                    font-family: "Yeseva One", "sans-serrif";
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    
        .cardBottom{
            background-color: #F7F7F7;
            width: 142px;
            position: absolute;
            top: 85px;
            left: 8.5px;
            padding: 12px 12px 0 12px;
            cursor: pointer;

            .cardReadMore{
                display: none;
            }

            &:hover{
                top: 75px;

                .cardReadMore{
                    display: block;
                    padding-bottom: 12.5px;
                }
            }    

            .theme{
                font-family: "Roboto", "Sans-serrif";
                font-size: 13px;
                line-height: 12px;
                font-weight: 600;
                letter-spacing: 0.4px;
                width:fit-content;
                padding: 2px 4px;
                color: #EE6B44;
                background-color: #E5E5E8;
            }

            .title{
                font-family: "Yeseva One", "Sans-serrif";
                font-size: 18px;
                line-height: 18px;
                font-weight: 300;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .content{
                font-family: "Roboto", "Sans-serrif";
                font-size: 14px;
                line-height: 14px;
                font-weight: 200;
            }

            #plainButton{
                height: 40px;
                border: 1.5px solid #EE6B44;
            }
        }
    }
}

@media(min-width: 900px){
    #latestProgramsPreviewComponent{
        column-gap: 24px;
        margin: 60px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0 5%;
        margin-bottom: 150px;
        row-gap: 230px;

        .latestProgram{
            margin: 0;

            .cardTop{
                height: 300px;
                width: 325px;
                padding: 22px 0 0 26px;
    
                .date{
                    width: 90px;
                    height: 80px;
                    padding-top: 6px;
            
                    .day{
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 28px;
                    }
            
                    .month{
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 500;
                        letter-spacing: 1px;
                    }
            
                    .year{
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 28px;
                    }
                }
            }
        
            .cardBottom{
                width: 270px;
                top: 200px;
                left: 17px;
                padding: 25px 25px 0 25px;
    
                &:hover{
                    top: 160px;
    
                    .cardReadMore{
                        padding-bottom: 25px;
                    }
                }    
    
                .theme{
                    font-size: 13px;
                    line-height: 24px;
                    font-weight: 600;
                    letter-spacing: 0.7px;
                    padding: 3px 8px;
                }
    
                .title{
                    font-size: 25px;
                    line-height: 35px;
                    font-weight: 400;
                }
    
                .content{
                    font-size: 15px;
                    line-height: 27px;
                    font-weight: 400;
                }
    
                #plainButton{
                    height: 40px;
                    border: 1.5px solid #EE6B44;
                }
            }
        }
    }
}

@media (min-width:901px) and (max-width:1699px){ // Ipad flipped
    #latestProgramsPreviewComponent{
        grid-template-columns: 1fr 1fr;
        margin: 40px auto 0 160px;
        row-gap: 150px;

        .latestProgram{
            margin-bottom: 90px;
        }
    }
}
