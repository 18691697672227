#sponsorsListComponent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    position: relative;
    top: 14%;

    .sponsorsList{
        margin: 0 auto;
        img{
            height: 85px;
            width: 85px;
            object-fit: contain;
        }
    }
}

@media(min-width:900px){
    #sponsorsListComponent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        padding: 10px;
        position: relative;
        top: 22%;

        .sponsorsList{
            img{
                height: 120px;
                width: 120px;
                object-fit: contain;
            }
        }
    }
}