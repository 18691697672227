#contactUsComponent{
    padding-top: 5.2em;
    margin-bottom: 300px;

    #contactUsBanner{
        position: relative;
        height: 150px;

        img{
            width: 100%;
            height: 100%;
        }

        h2{
            color: #FFFFFF;
            position: absolute;
            top: 18%;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    #contactUs{
        display: grid;
        grid-template-columns: 1fr;
    }
}


@media(min-width:900px){
    #contactUsComponent{
        padding-top: 6.5em;
        margin-bottom: 50px;
    
        #contactUsBanner{
            height: 300px;
    
            h2{
                font-size: 52px;
                font-weight: 550;
                top: 34%;
            }
        }
    
        #contactUs{
            display: grid;
            grid-template-columns: 1.2fr 1fr;
            padding: 5.5% 18%;
        }
    }
}
