
#bannerImageDiv{
    padding-top: 8vh;
    padding-right: 4px;
    position: relative;

    #bannerImage{
        width: 36vw;
        height: 36vw;
        border-radius: 50%;
        z-index: 1;
        position: relative;
    }

    #circle{
        width: 36vw;
        height: 36vw;
        position: absolute;
        top: 5.5vh;
        left: 3.2vw;
        z-index: 0;
    }

    #circleWBg{
        width: 8.5vw;
        position: absolute;
        left: 30vw;
        top: 6vh;
    }
}

@media(min-width:900px){
    #bannerImageDiv{
        padding-top: 12vh;
        padding-left: 15px;
        position: relative;
    
        #bannerImage{
            width: 34vw;
            height: 32vw;
            border-radius: 50%;
            z-index: 1;
            position: relative;
        }
    
        #circle{
            width: 34vw;
            height: 32vw;
            position: absolute;
            top: 15px;
            left: 3.2vw;
            z-index: 0;
        }
    
        #circleWBg{
            width: 8.5vw;
            position: absolute;
            left: 28vw;
            top: 25px;
        }
    }
}
