#programsPageBody{
    padding-top: 4.8em;

    #programsPreview{
        padding: 0 8px;
        margin: 0 0 450px 0;

        #latestProgramsPreviewComponent{
            margin-bottom: 250px;
        }
    }
}

@media(min-width:900px){
    #programsPageBody{
        padding-top: 4.8em;
    
    
        #programsNavigation{
            margin: 0 18vw 0 10vw;
        }
    
        #programsPreview{
            padding-left: 30px;
            margin: 80px 0 480px 0;
    
            #latestProgramsPreviewComponent{
                margin-bottom: 250px;
            }
        }
    }
}