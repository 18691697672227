.coreAreaPreviewComponent{
    display: grid;
    grid-template-columns: 100%;
    row-gap: 20px;
    padding: 0 10vw;

    .coreAreaPreview{
        padding: 0 40px 30px 40px;
        color: #FFFFFF;
        display: grid;

                    
        &:first-of-type{
            background-color: #FC6539;
        }

        &:nth-of-type(2){
            background-color: #001D23;
        }

        &:nth-of-type(3){
            background-color: #FEA444;
        }

        &:nth-of-type(4){
            background-color: #001D23;
        }
        
        #coreAreasText{
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    #plainButton{
        margin-top: 15px;
        color: #FFFFFF;
        height: 40px;

        .rightArrow{
            top: 36%;
        }

        #rightArrowD{
            display: none;
        }

        #rightArrowW{
            display: block;
        }
    }
}

@media(min-width:900px){
    .coreAreaPreviewComponent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 28px;
        padding: 0 10vw;

        .coreAreaPreview{
            padding: 30px 40px;
            color: #FFFFFF;
            display: grid;
        }
    }
}