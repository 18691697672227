#aboutWIPFComponent{
    position: relative;
    padding: 0 20px;

    #aboutWIPFBackground{
        display: none;
    }

    #aboutWIPF{
        display: grid;
        grid-template-columns: 1fr;
        padding: 50px 0 0 0;

        #intro{
            width: 100%;

            span{
                background: linear-gradient(to top right, #FEA244, #FC693A);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        #content{
            display: grid;
            grid-template-columns: 1fr;

            #aboutUsImage{
                margin: 0 auto;
                height: 100%;
                display: block;

                p{
                    font-family: 'Roboto', sans-serif;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 700;
                    color: #909090;
                    text-transform: uppercase;
                    text-align: center;
                  }

                img{
                    width: 370px;
                    height: 250px;
                    display: block;
                    object-fit: cover;
                    margin-bottom: 12px;
                }
            }

            #textContent{
                font-family: 'Roboto', sans-serif;
                color: #595959;
                margin-top: -5px;

                p{
                    font-size: 17px;
                    margin-top: 10px;
                }

                h2{
                    margin-top: 20px;
                    font-family: 'Yeseva One', cursive;
                    font-size: 21px;
                    line-height: 28px;
                    position: relative;
                    display: inline-block;
                    padding-left: 26px;
            
                    &::before{
                        // display: inline-block;
                        content: "";
                        height: 100%;
                        width: 3px;
                        background-color: #FC6539;
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }
}


@media(min-width:900px){
    #aboutWIPFComponent{
        position: relative;
        padding: 0 0;
        margin-bottom: 70px;
    
        #aboutWIPFBackground{
            position: absolute;
            height: 100%;
            width: 30vw;
            background-color: #f0efef;
        }
    
        #aboutWIPF{
            position: relative;
            padding: 60px 220px 0 160px;
    
            #intro{
                // padding-right: 100px;
                width: 90%;
    
                h1{
                    font-family: 'Yeseva One', cursive;
                    font-size: 50px;
                    line-height: 55px;
    
                    span{
                        background: linear-gradient(to top right, #FEA244, #FC693A);
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
    
            #content{
                display: grid;
                grid-template-columns: 0.3fr 1fr;
                margin-bottom: 40px;
    
                #aboutUsImage{
                    margin: 10px 30px 0 0;
                    height: 100%;
                    display: block;
    
                    img{
                        width: 570px;
                        height: 400px;
                        display: block;
                        object-fit: cover;
                    }
                }

                #textContent{
                    h2{
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

