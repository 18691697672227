
@mixin animatedButton($primaryColour, $secondaryColour){
    border: 1px $primaryColour solid;
    position: relative;
    border-radius: 30px;
    background-image: linear-gradient(to top right, #FEA244, #FC693A);
    color: $secondaryColour;
    font-size: 17px;
    line-height: 23px;
    cursor: pointer;
    height: 48px;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    line-height: 23px;
}
#animatedButton{
    @include animatedButton(#FC693A, #FFFFFF);
}

@media(min-width:900px){
    #animatedButton{
        height: 60px;
        padding: 0 25px;
    }
}
