@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@350;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p1{
  font-family: 'Roboto', sans-serif;
  line-height: 30px;
  font-size: 18px;
}

.p2{
  font-size: 14px;
  line-height: 22px;
  font-weight: 350;
  font-family: 'Roboto', sans-serif;
}

.p3{
  font-size: 12px;
  line-height: 20px;
  font-weight: 350;
  font-family: 'Roboto', sans-serif;
}

.h1{
  font-family: 'Yeseva One', cursive;
  line-height: 52px;
  font-size: 52px;
  font-weight: 550;
}

.h2{
  font-size: 38px;
  line-height: 45px;
  font-family: 'Yeseva One', sans-serif;
  font-weight: 400;
}

.h4{
  font-family: 'Yeseva One', sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
}

.h5{
  font-family: 'Yeseva One', sans-serif;
  font-size: 12px;
  line-height: 13px;
}

@media(min-width:900px){
  .h1{
    line-height: 82px;
    font-size: 82px;
    font-weight: 600;
  }

  .h2{
    font-size: 42px;
    line-height: 45px;
    font-family: 'Yeseva One', sans-serif;
    font-weight: 400;
  }

  .h4{
    font-family: 'Yeseva One', sans-serif;
    font-size: 15px;
    line-height: 27px;
    font-weight: 600;
  }

  .h5{
    font-family: 'Yeseva One', sans-serif;
    font-size: 14px;
    line-height: 15px;
  }

  .p1{
    font-family: 'Roboto', sans-serif;
    line-height: 30px;
    font-size: 19px;
  }

  .p2{
    font-size: 16px;
    line-height: 22px;
    font-weight: 350;
    font-family: 'Roboto', sans-serif;
  }

  .p3{
    font-size: 14px;
    line-height: 22px;
    font-weight: 350;
    font-family: 'Roboto', sans-serif;
  }
}