#pageIntroComponent {
  position: relative;
  height: 150px;

  img{
      width: 100%;
      height: 100%;
  }
  
  h2{
    color: #FFFFFF;
    position: absolute;
    top: 18%;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@media(min-width:900px){
  #pageIntroComponent {
    height: 300px;

    h2{
      font-size: 52px;
      font-weight: 550;
      top: 34%;
    }
  }
}
