a{
    text-decoration: none;
    color: #000000;
}


#navModal{
    top: 0;
    position: fixed;
    height: 100%;
    width: 40%;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 6;
}

#navBar{
    display: flex;
    background-color: #FFFFFF;
    height: 5em;
    padding: 0 18px;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;

    #navLogo img{
        height: 4em;
    }

    .hamburgerMenu img{
        height: 2em;
        margin-right: 35px;
    }

    #navItemsMobile{
        display: none;
    }

    .navItems{
        // display: block;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 15px;
        padding-top: 95px;
        position: fixed;
        height: 100%;
        background-color: white;
        z-index: 5;
        top: 0;
        right: 0;
        width: 60%;

        .hamburgerMenu img{
            height: 1.8em;
            position: absolute;
            right: -18px;
            top: 22px;
        }
    
        ul{
            padding: 6px 30px 0 15px;
            margin-left: 35px;
            // height: 40px;
            // min-width: 80px;
            text-align: left;
            text-decoration: none;
        }
    
        ul:hover{
            color: #FC693A;
            cursor: pointer;
        }
    
        ul:first-of-type{
            padding: 6px 32px 0 30px;
        }

        .active{
            color: #FC693A;
            cursor: pointer;
        }
    
    }

    .nwaLink{
        display: none;
    }
}



@media(min-width:900px){
    #navModal{
        display: none;
    }

    #navBar{
        height: 6em;
        justify-content: space-around;
        padding: 0;
        width: 100%;
        z-index: 2;
        top: 0;

        #navLogo img{
            height: 4.5em;
        }

        #navItemsMobile{
            display: flex;
        }

        .hamburgerMenu{
            display: none;
        }
        
        .nwaLink{
            display: block;
        }
    
        .navItems{
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            // justify-content: space-around;
            font-size: 20px;
            line-height: 22px;
            padding-top: 2px;
            position: relative;
            height: inherit;
            width: auto;
    
            ul{
                height: 40px;
                margin: 0;
                min-width: 80px;
                text-align: center;    
            }
    
            ul:hover{
                color: #FC693A;
                cursor: pointer;
            }
    
            ul:first-of-type{
                padding: 6px 32px 0 30px;
            }
    
            .active{
                color: #FFFFFF;
                cursor: pointer;
                background-image: url("../../assets/images/activeMenu.webp");
                background-repeat: no-repeat;
                background-size: 140px 40px;
                background-position: 2px -2px;
            }
        }
    }
}