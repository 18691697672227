#footerComponent{
    background-color: #001D23;
    background-image: url("../../assets/images/footerLine.png");
    border: 1px solid black;
    height: 500px;
    position: relative;
    bottom: 0;
    margin-top: 250px;

    #developer{
        color: #BD5332;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        text-align: center;

        .link{
            color: #FFFFFF;
        }
    }

    #footerContentArea{
        display: grid;
        grid-template-columns: 1fr;;
        row-gap: 10px;
        height: 120%;
        width: 80%;
        background-color: #F7F7F7;
        position: absolute;
        left: 10%;
        top: -40%;
        overflow: hidden;
        border: 1px solid #CFD9DE;

        #footerOutro{
            display: flex;
            align-items: center;
            padding: 10px 60px 0 50px;
        }

        #footerSocialMobile{
            display: block;
            margin: 0 auto;

            img{
                height: 30px;
                margin: 0 10px
            }
        }

        #footerSocials{
            display: none;
        }

        #footerQuickLinks{
            margin: 10px auto 0 auto;

            h4{
                font-size: 30px;
                line-height: 30px;
                font-family: 'Yeseva One', sans-serif;
                color: #000000;
                margin: 0;
            }

            #link{
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: 15px 0 0 15px;
            }
        }
    }
}

@media(min-width:900px){
    #footerComponent{
    
        #footerContentArea{
            grid-template-columns: 1fr 1fr 1fr;
                
            #footerOutro{
                padding: 0 60px 0 50px;
            }

            #footerSocialMobile{
                display: none;
            }
    
            #footerSocials{
                display: block;
                height: 100%;
                margin-top: 25px;
    
                #twitter{
                    height: 450px;
                    overflow-y: scroll;
                    border-bottom: 1px solid #CFD9DE;
                }
    
                #instagram{
                    margin-top: 20px;
                    border-bottom: 1px solid #CFD9DE;
    
                    p{
                        margin: 0;
                    }              
                }
            }
    
            #footerQuickLinks{
                margin: 160px auto 0 auto;
    
                h4{
                    font-size: 50px;
                    line-height: 40px;
                }
            }
        }
    }
}
