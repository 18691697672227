#WIPFTeamPreviewComponent{
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    margin: 10px 0 80px 0;

    #sectionIntro{
        display: none;
    }

    #teamIntro{
        margin-bottom: 30px;
        position: relative;
        top: 10%;

        #miniSectionIntroComponent{
            position: relative;
            top: -10.5%;
        }

        p{
            display: none;
        }

        #animatedButton{
            display: none;
        }
    }

    #teamImages{
        #joinUs{
            margin: 20px 0 30px 0;
            font-size: 17px;
            line-height: 30px;
            color: #595959;
        }

        #animatedButton{
            height: 50px;
            display: block;
            margin: 0 auto 50px auto;
        }
    }
}


@media(min-width:900px){
    #WIPFTeamPreviewComponent{
        padding: 0 160px;
        grid-template-columns: 0.3fr 0.7fr;
        margin: 0 0 80px 0;

        #sectionIntro{
            display: block;
        }

        #teamIntro{
            margin: 0;
            position: relative;
            top: 10%;
            margin-left: 0;

            p{
                display: block;
                margin: 250px 0 40px 0;
                font-size: 17px;
                line-height: 30px;
                color: #595959;
            }

            #animatedButton{
                height: 50px;
                padding: 0 15px;
                display: inline;
            }
        }

        #teamImages{
            #joinUs{
                display: none;
            }
    
            #animatedButton{
                display: none;
            }
        }    
    }
}

@media (min-width:901px) and (max-width:1699px){ // Ipad flipped
    #WIPFTeamPreviewComponent{
        padding: 0 60px;
        grid-template-columns: 1fr 1fr;
        margin: 0 0 80px 0;
    }
}

