#newsPreviewsComponent{
    position: relative;
    padding: 0 0 20px 0;
    background-color: #001D23;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
  
    #newsImageCover{
        display: none;
    }

    #newsContent{
        display: grid;
        grid-template-columns: 100%;
        padding: 0 10px;
    }

    #newsTitle{
        h2{
            color: #C2C2C2;
            font-size: 35px;
            line-height: 40px;
        }
    } 

    #newsPreview{
        display: grid;
        grid-template-columns: 100%;

        #newsPreviewCover{
            p, span{
                margin: 0;
                color: #C2C2C2;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'Roboto', sans-serif !important;
                line-height: 30px !important;
                font-size: 18px !important;
            }
        }

        #newsMetadata{
            text-align: center;
            padding-top: 5px;
            
            h4{
                color: #FFFFFF;
            }

            h5{
                color: #FC6539;
                font-weight: 400;
            }
        }

        #newsToggle{
            background-color:#192A2D;
            width: 60%;
            height: 80px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 15px auto 5px auto;

            
            #nextNews{
                margin: 3px;
                transform: rotate(180deg);
            }

        }

        #ovalButtonComponent{
            margin: 20px 0 0 35px;

            #ovalButton{
                border: 1px solid #283E42;
            }

            #ovalButton:hover{
                background-color: #283E42;
            }

        }
    }
}

@media(min-width:900px){
    #newsPreviewsComponent{
        height: 600px;
        grid-template-columns: 1.18fr 2fr;
        column-gap: 55px;
        margin-bottom: 70px;
        padding: 0;

        #newsTitle{
            h2{
                padding: 0;
                font-size: 42px;
                line-height: 45px;
            }
        } 

        #newsContent{
            position: relative;
            top: 5%;
            display: block;
        }
        #newsPreview{
            margin-top: 20px;
            grid-template-columns: 2fr 0.7fr;
            column-gap: 10px;

            #newsPreviewCover{
                p, span{
                    margin-top: 20px;
                    font-family: 'Roboto', sans-serif !important;
                    line-height: 30px !important;
                    font-size: 19px !important;
                }
            }


            #newsMetadata{
                text-align: left;
                padding-top: 25px;

                h5{
                    color: #FC6539;
                    font-weight: 700;
                }
            }

            h4{
                padding: 0 35px 0 0;
            }
        }

        #newsImageCover{
            display: block;
            height: 75%;
            width: 36vw;
            position: relative;
            top: 12.5%;
            margin-top: 0;
        }
    
        #newsImage{
            width: 36vw;
        }

        #newsPreview{
            #newsToggle{
                height: 120px;
                width: 100%;
                margin-top: 100px;
            }

            #ovalButtonComponent{
                margin: 35px 0 0 35px;
            }
        }        
    }
}