#landingPageBody{
       padding-top: 5em;

    .landingIntros{
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 110px;
        margin-top: 20px;
        background-color: #F7F7F7;
    }

    .coreAreasDiv{
        padding-bottom: 130px;
        background-color: #F7F7F7;
    }

    .newsPreviewsDiv{
        margin-bottom: 120px;
    }

    .latestProgramsDiv{
        margin-bottom: 70px;
    }

    .sponsorsDiv{
        margin-bottom: 280px;
    }

    .barMargin{
        margin: 0;
    }

    .barPadding{
        padding: 0;
    }

    #curvedLineBg1{
        position: absolute;
        top: 5vh;
        left: 0vh;
        z-index: 1;
    }
    #curvedLineBg2{
        position: absolute;
        top: 10vh;
        left: 8vw;
    }

    .introButtons{
        display: flex;
        padding-left: 5vw;

        button{
            margin-right: 24px;
        }
    }

    @-webkit-keyframes vibrate-1 {
        0% {
        -webkit-transform: translate(0);
                transform: translate(0);
        }
        20% {
        -webkit-transform: translate(-3px, 3px);
                transform: translate(-3px, 3px);
        }
        40% {
        -webkit-transform: translate(-3px, -3px);
                transform: translate(-3px, -3px);
        }
        60% {
        -webkit-transform: translate(3px, 3px);
                transform: translate(3px, 3px);
        }
        80% {
        -webkit-transform: translate(3px, -3px);
                transform: translate(3px, -3px);
        }
        100% {
        -webkit-transform: translate(0);
                transform: translate(0);
        }
    }
    @keyframes vibrate-1 {
        0% {
        -webkit-transform: translate(0);
                transform: translate(0);
        }
        20% {
        -webkit-transform: translate(-3px, 3px);
                transform: translate(-3px, 3px);
        }
        40% {
        -webkit-transform: translate(-3px, -3px);
                transform: translate(-3px, -3px);
        }
        60% {
        -webkit-transform: translate(3px, 3px);
                transform: translate(3px, 3px);
        }
        80% {
        -webkit-transform: translate(3px, -3px);
                transform: translate(3px, -3px);
        }
        100% {
        -webkit-transform: translate(0);
                transform: translate(0);
        }
    }
  
    .hoverAnimation:hover {
        animation: vibrate-1 3.5s linear infinite both;
    }
}

@media(max-width: 900px){

    #landingPageBody{
        padding-top: 2em;

        .landingIntros{
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 20px;
            padding-bottom: 90px;
        }

        #curvedLineBg1, #curvedLineBg2{
            display: none;
        }
        
        .landingIntroDiv{
            width: 100vw;
            margin: 0 auto;
            order: 2;
        }

        .hoverAnimation{
            order: 1;
            display: block;
            margin: 15px auto 0 auto;
        }

        .sponsorsDiv{
            margin-bottom: 420px;
        }
    }
}