#teamImagesPreviewComponent {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;

.profile {
  text-align: center;
  margin-bottom: 35px;

  img {
    width: 290px;
    height: 390px;
    object-fit: cover;
    margin-bottom: 8px;
  }

  h4{
    font-family: 'Yeseva One', cursive;
    font-size: 25px;
    line-height: 28px;
    text-transform: capitalize;
    margin: 0;
  }

  p{
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #909090;
    text-transform: uppercase;
    margin: 4px;
  }
}
}

@media(min-width:900px){
  #teamImagesPreviewComponent {
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
  }
}

