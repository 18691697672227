#coreAreaComponent{
    padding-top: 4em;
    display: grid;
    grid-template-columns: 1fr;

    .coreAreaContent{
        margin: 30px 2vw;

        .areaImageDiv{
            position: relative;
            width: 390px;
            height: 220px;
        }

        img{
            float: left;
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: 0px 0 2px 0;
        }

        .areaCaption{
            width: 85%;
            padding: 0 10px;
            position: absolute;
            top: 160px;
            right: 0;
            background-image: linear-gradient(to top right, #FEA244, #FC693A);
            color: #FFFFFF;
        }

        h1{
            margin: 0 0 2px 2px;
        }

        #coreAreaText{
            margin: 0 0 0 2px;
            padding: 0;
            text-align:unset;
            font-family: 'Roboto', sans-serif !important;
            font-size: 14px !important;
            line-height: 16px !important;
            font-weight: 400 !important;
        }

    }
}

@media(min-width:900px){
    #coreAreaComponent{
        padding-top: 6em;
    
        .coreAreaContent{
            margin: 55px 10vw 50px 10vw;
    
            .areaImageDiv{
                width: 500px;
                height:auto;
            }
    
            img{
                margin: 0px 20px 2px 0;
            }
    
            .areaCaption{
                top: 270px;
            }
    
            h1{
                margin: 0 0 2px 0;
            }
    
            #coreAreaText{
                margin: 0;
                text-align: justify;
                font-family: 'Roboto', sans-serif !important;
                font-size: 16px !important;
                line-height: 25px !important;
                font-weight: 400 !important;
            }
    
        }
    }
}