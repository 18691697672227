#landingIntroComponent{
    padding: 0 25px;
}

@media (min-width:900px){
    #landingIntroComponent{
        max-width: 600px;
        padding-top: 18vh;
        padding-bottom: 12px;
        padding-left: 18px;
        padding-left: 5vw;
    }
}



