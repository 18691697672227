#programsNavigationPreviewComponent{
    margin-top: 30px;    

    #programSearch{
        width: 100%;
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: 1fr;

        form{
            width: 77vw;
            margin: 0 auto 20px auto;

            #searchTitle{
                background-color: #FFFFFF;
                border: 1px solid #D4D4D8;
                box-shadow: 0 0 1px #D4D4D8;
                height: 37px;
                width: 100%;
                background: url("../../../../assets/images/searchIcon.png");
                background-size: 18px;
                background-repeat: no-repeat;
                background-position: 14px 50%;
                padding: 0 10px 0 40px;
                margin-left: -26px;
            }
        }



        #searchCriteria{
            display: grid;
            grid-template-columns: 0.5fr 0.5fr;
            column-gap: 20px;
            height: 37px;
            width: 90vw;
            margin: 0 auto;

            #searchTheme{
                background-color: #FFFFFF;
                border: 1px solid #D4D4D8;
                color: #4f4e4e;
                padding: 0 20px;
                width: 100%;
            }
    
            #searchYear{
                background-color: #FFFFFF;
                border: 1px solid #D4D4D8;
                color: #4f4e4e;
                padding: 0 20px;
                width: 100%;
            }
        }
        
        }
}

@media(min-width:900px){
    #programsNavigationPreviewComponent{
        margin-top: 50px;

        #programSearch{
            grid-template-columns: 0.4fr 0.6fr;
            column-gap: 100px;

            form{
                width: 100%;

                #searchTitle{
                    border: 2px solid #D4D4D8;
                    width: 85%;
                    padding: 0 40px;
                    margin: 0;
                }
            }


            #searchCriteria{
                grid-template-columns: 0.5fr 0.5fr;
                column-gap: 60px;
                margin: 0;
                width: auto;

                #searchTheme{
                    border: 2px solid #D4D4D8;
                    height: 43px;
                }
        
                #searchYear{
                    border: 2px solid #D4D4D8;
                }

            }
        }
    }
}