#aunthenticatorComponent{
    padding-top: 5em;
    margin: 2vw 0 0 5vw;

    input{
        display: block;
        margin: 4px 0;
        width: 200px;
    }

    button{
        margin: 10px 0;
        width: 100px;
    }
}