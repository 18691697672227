#cmsNavComponent{
    padding: 6.5em 6em 0 6em;

    #cmsNav{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 2px;
        row-gap: 2px;
        
            .compNav{
                height: 100px;
                border: 1px solid black;
                text-align: center;
                text-decoration: none;
                color: black;
        
                p{
                    font-size: 25px;
                }
            }
    }

}